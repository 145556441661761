html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

body {
  height: 100%;
  font-size: 14px;
}

#__next {
  height: 100%;
}

// Global progress bar styling
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #ff7621;
}
